export const parameters = {
	serviceUrl: 'https://briisk-i-dev-api.azure-api.net/bl',
	captchaKey: '6Ldx83goAAAAABn9BMqEjSZ9N3YB-11UGQkitERa',
	brandLabelSubscriptionKey: '6aeeca623d274b61bd75b25cd28d2cb8'

	// serviceUrl: 'http://localhost:7071',
	// serviceUrl: 'https://briisk-i-dev-api.azure-api.net/bl',
	// captchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
	// brandLabelSubscriptionKey: '6aeeca623d274b61bd75b25cd28d2cb8'
};
